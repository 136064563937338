import React from "react";
import styled from 'styled-components';
import { SideBarAdSense } from "../components/SideBarAdSense";
import { SideBarNews } from "../components/SideBarNews";
import { SideBarPopular } from "../components/SideBarPopular";
import { SideBarRecommend } from "../components/SideBarRecommend";
import { A8Square } from "./A8Square";
import { AmazonBlackFriday } from "./AmazonBlackFriday";

const Wrapper = styled.div`
  position: sticky;
  bottom: 0;

  display: flex;
  flex-direction: column;
  //flex-basis: 30%;
  //flex-grow: 0;
  align-self: flex-end;

  width: 100%;
  max-width: ${props => props.width};
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  top: calc(64px + 0.25rem);
  width: 100%;
  padding: 0 2rem;
  overflow: hidden;
  box-sizing: border-box;
`

export const SideBar = ({width="30%", shownAdSense}) => {
  return (
    <Wrapper id="sidebar" className="pc" width={width}>
      <Box>
        {
          shownAdSense && <SideBarAdSense />
        }
        {
          //shownAdSense && <A8Square title={`初めての方でも確定申告がラクラク完了【マネーフォワード クラウド確定申告】(20-0630)`} />
        }
        <SideBarNews />
        {
          //shownAdSense && <A8Square title={`未経験からプログラマーへの転職率95.1%！20代第二新卒/フリーター向け【GEEK JOB】(18-1217)`} />
        }
        {
          shownAdSense && <SideBarAdSense />
        }
        <SideBarRecommend />
        {
          //shownAdSense && <A8Square title={`年間1000人を輩出する【完全無料IT研修＆就活塾　ProgrammerCollege】(16-1031)`} />
        }
        {
          shownAdSense && <SideBarAdSense />
        }
        <SideBarPopular />
        {
          //shownAdSense && <A8Square title={"IT/Webエンジニアの転職なら【転職ドラフト】(21-0419)"} />
        }
        {
          shownAdSense && <SideBarAdSense />
        }
        {
        //<SidebarLink to={`/posts/page/1`}>ブログ</SidebarLink>
        //<SidebarLink to={`/homepage`}>ホームページ制作</SidebarLink>
        //<SidebarLink to={`/mentor`}>メンター依頼</SidebarLink>
        }
      </Box>
    </Wrapper>
  );
}
