import React from "react"
import styled from "styled-components"

import theme, { breakpoints } from "../../style/theme";

const Br = styled.br`
  display: none;
  @media screen and (max-width: ${breakpoints.sm}px) {
    display: block;
  }
`

export const SpBr = () => {
  return (
    <Br />
  )
}