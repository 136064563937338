import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ title, description, image, lang }) {
    const { pathname } = useLocation()

    const { site } = useStaticQuery(
        graphql`
    query {
        site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                siteUrl: url
                defaultImage: image
                defaultLang: lang
                twitterUsername
            }
        }
    }
    `
    )

    const {
        defaultTitle,
        titleTemplate,
        defaultDescription,
        siteUrl,
        defaultImage,
        defaultLang,
        twitterUsername,
    } = site.siteMetadata

    const img = image || defaultImage;

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: staticOrDynamic(siteUrl + img), // 動的ページの場合siteUrl + imageで'https://benzoinfojapan.orghttps://images.microcms-assets.io/protected/ap-northeast-1:4fa35fa7-a818-40f5-b6bb-89f712b0dee1/service/benzoinfo/media/NicolL.png'となってしまう
        lang: lang || defaultLang,
        url: `${siteUrl}${pathname}`
    }

    const ogTitle = titleTemplate.includes(seo.title) ? seo.title : titleTemplate.replace("%s", seo.title);

    function staticOrDynamic(imgPath) {
        const str = imgPath
        let array = str.split(/https:\/\//);
        return str.length <= 100 ? str : 'https://' + array[2]
    }

    return (
        <Helmet>
            <title>{ogTitle}</title>
            <html lang={seo.lang} />
            <template>{titleTemplate}</template>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta name="robots" content="index" />
            <meta property="og:site_name" content={`ITベンチャーの世界｜エンジニア × プログラミング × ビジネス × キャリア のノウハウ`} />
            <meta property="og:title" content={ogTitle} />
            <meta property="og:type" content={ogTitle === titleTemplate.replace("%s", defaultTitle) ? "website" : "article"} />
            <meta property="og:url" content={seo.url} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:image" content={seo.image} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={twitterUsername} />
            <meta name="twitter:title" content={ogTitle} />
            <meta name="twitter:description" content={seo.description} />
            <meta name="twitter:image" content={seo.image} />
            <link rel="canonical" href={seo.url}></link>
       </Helmet>
   )
}

SEO.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.string,
    lang: PropTypes.string,
}

SEO.defaultProps = {
    title: null,
    description: null,
    image: null,
    lang: null,
}

export default SEO
