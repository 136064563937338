import React, { useState } from 'react';
import styled from "styled-components";
import { A8Links } from '../utils/A8Links';
import { shuffle } from '../utils/Shuffle';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  margin: 1rem auto;
  padding: 1rem 0;
  box-sizing: border-box;
  a {
    display: contents;
    width: 100%;
  }
  img {
    width: 100%;
    height: auto;
    transition: .3s;
    &:hover {
      opacity: .6;
    }
  }
`

const DummyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  height: 250px;
  margin: 2rem auto;
  outline: 1px dotted #333;
  text-align: center;
  box-sizing: border-box;
`

export const A8Square = ({title, category}) => {

  let link =
    title
    ?
    A8Links.find(link => link.title === title)
    :
      category
      ?
      shuffle(A8Links.filter(link => link.categories.includes(category)))[0]
      :
      shuffle(A8Links.filter(link => link.sUrl))[0];

  if (!link) {
    link = shuffle(A8Links.filter(link => link.sUrl))[0];
  }

  const [a8Link, setA8Link] = useState(link);

  if (process.env.NODE_ENV === "production") {
    return (
      <Wrapper dangerouslySetInnerHTML={{__html: a8Link.sUrl}}></Wrapper>
    )
  } else {
    return (
      <DummyWrapper>A8.net</DummyWrapper>
    )
  }

};
