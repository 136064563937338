import React from "react";
import styled from "styled-components";
import { breakpoints } from "../style/theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.shownSideBar ? props.width : '100%'};
  height: 100%;
  @media screen and (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`

export const Main = ({width="70%", children, shownSideBar=true}) => {
  return (
    <Wrapper width={width} shownSideBar={shownSideBar}>
      {children}
    </Wrapper>
  )
}
