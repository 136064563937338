import React from "react"
import styled from "styled-components"

import ParticleField from 'react-particles-webgl';
import { breakpoints } from "../style/theme";

const Wrapper = styled.div`
  width: 100%;
`

const ParticleFieldWrapper = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
`

export const Particle = () => {
  const width =  typeof window === `undefined` ? 0 : window.innerWidth;

  const config = {
    showCube: false,
    dimension: '3D',
    velocity: 1,
    boundaryType: 'bounce',
    antialias: false,
    direction: {
      xMin: -1,
      xMax: 1,
      yMin: -1,
      yMax: 1,
      zMin: -1,
      zMax: 1
    },
    lines: {
      colorMode: 'rainbow',
      color: '#1b1f22',
      transparency: 0.9,
      limitConnections: true,
      maxConnections: 5,
      minDistance: width <= breakpoints.sm ? 50 : 200,
      visible: true
    },
    particles: {
      colorMode: 'rainbow',
      color: '#3FB568',
      transparency: 0.9,
      shape: 'circle',
      boundingBox: 'canvas',
      count: 300,
      minSize: 20,
      maxSize: 50,
      visible: false
    },
    cameraControls: {
      enabled: false,
      enableDamping: true,
      dampingFactor: 0.2,
      enableZoom: false,
      autoRotate: true,
      autoRotateSpeed: 0.3,
      resetCameraFlag: true
    },
    limitConnections: true,
    maxConnections: 0
  };

  return (
    <Wrapper>
      <ParticleFieldWrapper>
        <ParticleField config={config} />
      </ParticleFieldWrapper>
    </Wrapper>
  )
}
