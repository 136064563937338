import React from "react";
import styled from "styled-components";
import theme, {breakpoints} from "../style/theme";

const Title = styled.h2`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 2rem 0 0;
  padding: 0 2rem 0.25rem 1rem;
  font-family: "Montserrat", "游ゴシック",YuGothic,"ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  text-align: left;
  letter-spacing: .13em;
  text-transform: uppercase;
  word-break: break-word;
  border-left: 1px solid ${theme.palette.text.primary};
  border-bottom: 1px solid ${theme.palette.text.primary};

  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size: 1.35rem;
  }
`

export const SideBarSubTitle = ({en, children}) => {
  return (
    <Title id={children.toString().replace(" ", "-").toLowerCase()} backgroundText={en} data-en={en}>{children}</Title>
  )
}