import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import Link from "../components/Link";
import { makeStyles } from "@material-ui/styles";
import { Box, Drawer, IconButton, ListItem } from "@material-ui/core";
import { MdClose } from "react-icons/md";
import theme, { breakpoints, color, categoryColors } from "../style/theme";

const List = styled.ul`
  color: ${theme.palette.common.white};
  margin: .25rem 0 1rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  list-style: none;
  box-sizing: border-box;
  li {
    margin-bottom: ${theme.spacing(0.5)};
  }
  a {
    text-decoration: none;
    margin: 0.5rem 0;
    padding: 0 16px;
    box-sizing: border-box;
    color: ${theme.palette.text.primary};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Title = styled.h2`
  margin: .5rem 0 0;
  padding: 0 1rem;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
`

const useStyles = makeStyles(theme => ({
  drawer: {
    minWidth: 300,
    zIndex: 999,
  },
}));

export default ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              components {
                footer {
                  columns {
                    heading
                    links {
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({
        site: {
          siteMetadata: {
            title,
            components: {
              footer: {
                columns
              }
            }
          }
        }
      }) => (
        <Drawer
          classes={{ paper: classes.drawer }}
          open={open}
          onClose={onClose}
        >
          <Box display="flex" flexDirection="column" padding={1}>
            <Box display="flex" alignItems="center">
              <IconButton onClick={onClose}>
                <MdClose />
              </IconButton>
            </Box>
            {
              columns.map((column, i) => {
                return (
                  <>
                    <Title>{column.heading}</Title>
                    <List key={i}>
                      {column.links.map((link, j) => {
                        return (
                          <ListItem button key={link.title} to={link.url} component={Link}>
                            {link.title}
                          </ListItem>
                        );
                      })}
                    </List>
                  </>
                )
              })
            }
          </Box>
        </Drawer>
      )}
    />
  );
};
