import React from "react"
import styled from "styled-components"
import theme from "../style/theme"

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 100px auto;
  box-sizing: border-box;
  cursor: pointer;
  color: ${theme.palette.primary.main};
  transition: .3s;
  &:hover {
    color: ${theme.palette.secondary.main};
    fill: ${theme.palette.secondary.main};
  }
`

const Arrow = styled.span`
  width: 12px;
  height: 12px;
  margin: auto auto .5rem;
  transition: .3s;
`

const Title = styled.span`
  font-size: 16px;
  font-family: 'Montserrat', serif;
  font-weight: 600;
  letter-spacing: .13rem;
  &:hover {
    color: ${theme.palette.secondary.main};
  }
`

export const ScrollTop = () => {
  return (
    <Wrapper onClick={() => {
      if (document && window) {
        window.scrollBy({
          top: -document.body.clientHeight,
          behavior: 'smooth'
        });
      }
    }}>
      <Arrow>
        <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 10l6.5-7 6.5 7" stroke="currentColor" strokeLinecap="square"></path></svg>
      </Arrow>
      <Title>TOP</Title>
    </Wrapper>
  )
}