import React from 'react';
import { Link } from 'gatsby';
import styled from "styled-components";
import img from '../../static/amazon-black-friday.png'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  margin: 2rem auto;
  box-sizing: border-box;
  border: 1px solid #211f1f;
  a {
    transition: .3s;
    &:hover {
      opacity: .6;
    }
  }
  img {
    width: 100%;
  }
`

export const AmazonBlackFriday = () => {
  return (
    <Wrapper>
      <Link to={'/posts/86'}>
        <img src={img} alt="【Amazon ブラックフライデー 2021】最新Apple製品など、今すぐ買いたいアイテムリスト" />
      </Link>
    </Wrapper>
  )
};
