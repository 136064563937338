import { useStaticQuery, graphql } from "gatsby";

export const GetCategoryTags = (category) => {
  const { allMdx: {edges} } = useStaticQuery(
    graphql`
      query {
        allMdx {
          edges {
            node {
              frontmatter {
                id
                title
                category
                description
                createdDate
                updatedDate
                tags
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 1280) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  let tags = [];
  const posts = edges.filter(edge => edge.node.frontmatter.category == category);
  posts.map(post => {
    post.node.frontmatter.tags.map(tag => {
      if (!tags.includes(tag)) {
        tags.push(tag);
      }
    })
  });
  return (tags.sort());
};
