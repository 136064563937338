import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Marquee from "react-fast-marquee";
import theme from "../style/theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0;
`

const MarqueeWrapper = styled.div`
  margin: .15rem 0;
`

const Tag = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 1.25rem;
  //padding: 0.25rem 0.75rem;
  color: ${theme.palette.white}!important;
  //border: 1px solid ${theme.palette.secondary.main};
  background-color: transparent;
  //border-radius: 16px;
  outline: 0;
  font-size: 0.8125rem;
  box-sizing: border-box;
  white-space: nowrap;
  text-decoration: none!important;
  letter-spacing: .13em;
  transition: .1s;
  &:hover {
    color: ${theme.palette.secondary.main}!important;
    ${theme.palette.rainbow}
    //background-color: ${theme.palette.blue};
    //border: 1px solid ${theme.palette.blue};
  }
`

export const MarqueeTags = ({ tags, speed=20 }) => {
  return (
    <Wrapper>
      <MarqueeWrapper>
        <Marquee key={"tag-mk-1"} speed={speed} gradient={false} pauseOnHover={true}>
          {
            tags.map(tag => {
              return <Tag key={`${tag.tag}-tag-mk-1`} to={`/tag/${tag.tag}/page/1`}>#{tag.tag}</Tag>
            })
          }
        </Marquee>
      </MarqueeWrapper>
      <MarqueeWrapper>
        <Marquee key={"tag-mk-2"} speed={30} gradient={false} pauseOnHover={true}>
          {
            tags.reverse().map(tag => {
              return <Tag key={`${tag.tag}-tag-mk-2`} to={`/tag/${tag.tag}/page/1`}>#{tag.tag}</Tag>
            })
          }
        </Marquee>
      </MarqueeWrapper>
    </Wrapper>
  )
}
