import React, { useState } from "react";
import styled from "styled-components";
import { CssBaseline } from "@material-ui/core";
import { Header } from "../components/Header";
import SEO from "../components/Seo";
import { Main } from "../components/Main";
import { SideBar } from "../components/SideBar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";
import { FirstView } from "../components/FirstView";
import { ScrollTop } from "./ScrollTop";
import theme, { breakpoints } from "../style/theme";

const Wrapper = styled.div``
const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: ${breakpoints.lg}px;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: ${theme.palette.white};
  @media screen and (max-width: ${breakpoints.lg}px) {
    padding: 0 1.5rem;
  }
`

export default ({ title, description = null, image = null, firstView = false, elevateAppBar = true, shownSideBar = true, children, shownAdSense = true }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleToggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  return (
    <Wrapper>
      <SEO title={title} description={description} image={image} />
      <CssBaseline />
      <Header
        onToggleDrawer={handleToggleDrawer}
        elevation={Number(elevateAppBar)}
      />
      {
        firstView && <FirstView />
      }
      <Container id={"container"}>
        <Main width="65%" shownSideBar={shownSideBar}>
          {children}
        </Main>
        {
          shownSideBar && <SideBar width="30%" shownAdSense={shownAdSense}  />
        }
      </Container>
      <ScrollTop />
      <Footer />
      <Drawer open={isDrawerOpen} onClose={handleToggleDrawer} />
    </Wrapper>
  );
};
