import React from "react";
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby";
import { SideBarSubTitle } from "./SideBarSubTitle";
import { Link } from "gatsby";
import theme, {breakpoints} from "../style/theme";

const Wrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
`

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
`

const Article = styled(Link)`
  width: 100%;
  height: 100%;
  margin: .75rem 0;
  background: transparent;
  background-color: transparent;
  border: none;
  text-decoration: none!important;
  text-align: left;
  color: ${theme.palette.primary.main};
  transition: .3s;
  &:hover {
    background-color: transparent!important;
    border: none;
    box-shadow: none;
    ${theme.palette.rainbow}
  }
`

export const SideBarPopular = () => {

  const { allMdx: {edges} } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            image
            templates {
              posts {
                pathPrefix
              }
            }
          }
        }
        allMdx(
          filter: {
            frontmatter: { id: { in: ["55","61""28","22","50","8"] } }
            fileAbsolutePath: { regex: "/content/posts/" }
          }
        ) {
          edges {
            node {
              excerpt(pruneLength: 50, truncate: true)
              fileAbsolutePath
              frontmatter {
                id
                title
                category
                description
                tags
                createdDate
                updatedDate
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 720) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <Wrapper className={"pc-block"}>
      <SideBarSubTitle>Popular</SideBarSubTitle>
      <LinkWrapper>
      {
        edges && edges.map((post, i) => (
          (
            <Article key={i} to={`/posts/${post.node.frontmatter.id}`} >{post.node.frontmatter.title}</Article>
          )
        ))
      }
      </LinkWrapper>
    </Wrapper>
  );
}
