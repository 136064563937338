import React from "react";
import styled from "styled-components"
import { StaticQuery, graphql, Link } from "gatsby";
import { Grid } from "@material-ui/core";
import Picture from "../components/Picture";
import CustomLink from "../components/Link";
import { MarqueeTags } from "../components/MarqueeTags";
import theme, {breakpoints} from "../style/theme";

const Wrapper = styled.footer`
  position: relative;
  margin-top: 12rem;
  color: ${theme.palette.common.white};
  background-color: ${theme.palette.primary.main};
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-top: 5rem;
  }

  a {
    color: ${theme.palette.white};
    text-decoration: none;
    font-size: .9rem;
    transition: .3s;
    &:hover {
      text-decoration: none;
      color: ${theme.palette.white};
    }
  }
  //&::before {
  //  content: 'CREATORS';
  //  position: absolute;
  //  top: calc(-10rem + 0.2rem);
  //  left: 0.2rem;
  //  width: calc(100% - 0.2rem);
  //  color: ${theme.palette.blue};
  //  font-size: 10rem;
  //  font-family: 'Montserrat', serif;
  //  text-align: center;
  //  z-index: -1;
  //  @media screen and (max-width: ${breakpoints.md}px) {
  //    top: calc(-3rem + 0.1rem);
  //    left: 0.1rem;
  //    width: calc(100% - 0.1rem);
  //    font-size: 3rem;
  //  }
  //}
  &::after {
    content: 'VENTURE WORLD';
    position: absolute;
    top: -10rem;
    width: 100%;
    color: ${theme.palette.primary.main};
    background: rgb(27,31,34);
    ${theme.palette.rainbow}
    //background: linear-gradient(0deg, rgba(27,31,34,1) 40%, rgba(250,250,250,1) 100%);
    //-webkit-background-clip: text;//テキストでくり抜く
    //-webkit-text-fill-color: transparent;//くり抜いた部分は背景を表示
    font-size: 10rem;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    text-align: center;
    z-index: -1;
    @media screen and (max-width: ${breakpoints.md}px) {
      top: -3rem;
      font-size: 3rem;
    }
  }
`

const List = styled.ul`
  margin: .5rem 0;
  padding: 0 1rem;
  box-sizing: border-box;
  list-style: none;
  border-left: 1px solid ${theme.palette.secondary.main};
  transition: .3s;
  li {
    margin-bottom: 0.5rem;
  }
  a {
    color: ${theme.palette.white};
    text-decoration: none;
    font-size: .9rem;
    letter-spacing: .13em;
    transition: .3s;
    &:hover {
      text-decoration: none;
      ${theme.palette.rainbow}
    }
  }
`

const Container = styled.div`
  width: 100%;
  max-width: ${breakpoints.lg}px;
  padding: 2rem 0;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: ${breakpoints.lg}px) {
    padding: 2rem 1rem;
  }
`


const SNSWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const SNSIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

const CopyrightWrapper = styled.p`
  width: 100%;
  text-align: center;
`

const Copyright = styled.small`
  //font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  letter-spacing: .13em;
`

const Title = styled.h3`
  margin: 0;
  font-size: 1.4rem;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: ${theme.palette.secondary.main};
  letter-spacing: .13em;
`

const FooterColumnsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  padding: 0;
  box-sizing: border-box;
  @media screen and (max-width: ${breakpoints.md}px) {
    flex-direction: column;
  }
`

const FooterColumns = ({ columns }) => {
  return (
    <FooterColumnsWrapper>
      {columns.map(column => {
        return (
          <Grid xs={12} sm={12} item key={column.heading}>
            <Title>{column.heading}</Title>
            <List>
              {column.links.map(link => {
                return (
                  <li key={link.title}>
                    <CustomLink to={link.url} styles={{}}>
                      {
                        link.icon && <Picture relativePath={link.icon} />
                      }
                      {link.title}
                    </CustomLink>
                  </li>
                );
              })}
            </List>
          </Grid>
        );
      })}
    </FooterColumnsWrapper>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              components {
                footer {
                  columns {
                    heading
                    links {
                      title
                      icon
                      url
                    }
                  }
                  copyright
                }
              }
            }
          }
          allMdx {
            group(field: frontmatter___tags) {
              tag: fieldValue
            }
          }
        }
      `}
      render={({
        site: {
          siteMetadata: {
            components: {
              footer: { columns, copyright }
            }
          }
        },
        allMdx,
      }) => {
        return (
          <Wrapper>
            <Container>
              <MarqueeTags tags={allMdx.group} />
              <FooterColumns columns={columns} />

              <SNSWrapper>
                {
                //<img width="120" src="/images/icons/follow-me.svg" />
                }
                <SNSIconWrapper>
                  {
                  //<a href="https://github.com/creator-haduki" target="_blank" rel="noopener noreferrer">
                  //  <img width="18" style={{"margin-right":"4px"}} src="/images/icons/github.png" />
                  //</a>
                  }
                  <a href="https://www.instagram.com/creator_haduki/" target="_blank" rel="noopener noreferrer">
                    <img width="20" src="/images/icons/instagram.png" />
                  </a>
                  <a href="https://twitter.com/creator_haduki" target="_blank" rel="noopener noreferrer">
                    <img width="32" src="/images/icons/twitter.svg" />
                  </a>
                  {
                  //<a href="https://note.com/creator_haduki" target="_blank" rel="noopener noreferrer">
                  //  <img width="40" src="/images/icons/note.svg" />
                  //</a>
                  }
                  <a href="/rss.xml" target="_blank" rel="noopener noreferrer">
                    <img width="20" src="/images/icons/rss.svg" />
                  </a>
                </SNSIconWrapper>
              </SNSWrapper>
              <CopyrightWrapper>
                <Copyright>&copy; 2020 {copyright}</Copyright>
              </CopyrightWrapper>
            </Container>
          </Wrapper>
        );
      }}
    />
  );
};
