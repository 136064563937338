import React from "react"
import styled, { keyframes } from "styled-components"

import { Particle } from "./../components/Particle"
import { SpBr } from "./../components/parts/SpBr"
import theme, { breakpoints } from "../style/theme";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 70vh;
  background-color: ${theme.palette.primary.main};
  color: ${theme.palette.common.white};
  z-index: 0;
`

const TextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const KeyframeFadeIn = keyframes`
  from {
    opacity: 0;
    top: -5%;
  }
  to {
    opacity: 1;
    top: 0;
  }
`;

const Title = styled.h1`
  position: relative;
  margin: 1rem 0;
  text-align: center;
  font-size: 2.4rem;
  //font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  letter-spacing: .13em;
  ${theme.palette.rainbow}

  //animation-duration: 1.0s;
  //animation-name: ${KeyframeFadeIn};
  //animation-delay: 0.5s;
  //animation-timing-function: linear;
  //animation-fill-mode: forwards;
  //opacity: 0;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 2.0rem;
  }
  //&:before {
  //  position: absolute;
  //  left: 0;
  //  right: 0;
  //  bottom: 0;
  //  content: '';
  //  margin: 0 auto -1rem;
  //  width: 40px;
  //  height: 1px;
  //  background-color: white;
  //}
`

const Description = styled.p`
  position: relative;
  padding: 0 1rem;
  margin: 1rem 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: .13em;
  line-height: 1.8rem;
  ${theme.palette.rainbow}

  //animation-duration: 1.0s;
  //animation-name: ${KeyframeFadeIn};
  //animation-delay: 1.5s;
  //animation-timing-function: linear;
  //animation-fill-mode: forwards;
  //opacity: 0;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 1.0rem;
  }
`

export const FirstView = () => {
  return (
    <Wrapper id={"first-view"}>
      {
      //<Particle />
      }
      <TextWrapper>
        <Title>ITベンチャーエンジニアの日常</Title>
        <Description>Each creator creates the world.</Description>
        {
        //<Description>一つのベンチャーが<SpBr />世界をクリエイトする</Description>
        }
      </TextWrapper>
    </Wrapper>
  )
}
