import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { Link, StaticQuery, graphql } from "gatsby";
import theme, { breakpoints } from "../style/theme"
import {
  IconButton
} from "@material-ui/core";
import { MdMenu } from "react-icons/md";
import { GetCategoryTags } from "./../utils/Utils";

const Wrapper = styled.header`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;

  background-color: ${theme.palette.primary.main};
  transition: .2s;
  z-index: 100;
  a {
    text-decoration: none;
    font-size: .9rem;
    &:hover {
      text-decoration: none
    }
  }
  ul {
    padding: 0;
    list-style: none;
  }
  li {
    margin-bottom: .5rem;
  }
  &[data-active='true'] {
    background-color: ${theme.palette.primary.main};
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    color: ${theme.palette.common.white};
  }
`

const Main = styled.div`
  width: 100%;
  padding: 0;
  background-color: ${theme.palette.primary.main};
  z-index: 200;
`

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: ${breakpoints.lg}px;
  margin: 0 auto;
  padding: 0;
  color: ${theme.palette.common.white};
  @media screen and (max-width: ${breakpoints.lg}px) {
    padding: 0 1.5rem;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    padding: 0.5rem 1.5rem;
  }
`

const DummyBox = styled.div`
  transition: .5s;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Logo = styled(Link)`
  color: ${theme.palette.common.white};
`

const Title = styled.h1`
  margin: 0;
  font-size: 0.9rem;
  //font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: .13em;
`

const SubTitle = styled.small`
  font-size: 0.7rem;
  margin: 0 1.0rem;
  @media screen and (max-width: ${breakpoints.sm}px) {
    display: none;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  margin-left: auto;
`

const BlogSubMenuWrapper = styled.div`
  position: relative;
  width: 100vw;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  z-index: 150;
`

const BlogSubMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 48px 0;
  top: -100vh;
  width: 100%;
  transition: 0.5s;
  opacity: 1;
  background-color: ${theme.palette.lightGray};
  &[data-hover='true'] {
    top: 0;
    opacity: 1;
    background-color: ${theme.palette.lightGray};
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
`

const NavLink = styled(Link)`
  position: relative;
  padding: 1rem 1rem;
  transition: .3s;
  color: ${theme.palette.common.white};
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  &:hover {
    text-decoration: none;
    ${theme.palette.rainbow}
    //&:before {
    //  background-color: ${theme.palette.blue};
    //}
  }
  //&:before {
  //  position: absolute;
  //  content: "";
  //  right: 50%;
  //  bottom: 0.5rem;
  //  left: 50%;
  //  margin: auto;
  //  width: 4px;
  //  height: 4px;
  //  background-color: ${theme.palette.white};
  //  transition: .1s;
  //  border-radius: 50%;
  //}
`

const SubMenuWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`
const SubMenu = styled(Link)`
  width: 33%;
  margin: 0 0 1rem 0;
  position: relative;
  padding-left: calc(16px + 0.5rem);
  box-sizing: border-box;
  color: #666;
  text-decoration-line: underline;
  transition: .1s;
  &:before,
  &:after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    width: 16px;
    height: 16px;
    border-style: solid;
    border-color: #666;
    transition: .1s;
  }
  &:before {
    top: 0;
    left: 0;
    border-width: 1px;
  }
  &:after {
    top: 3px;
    left: 3px;
    border-width: 0 1px 1px 0;
    border-color: #00ccff;
  }
  &:hover {
    ${theme.palette.rainbow}
    //color: ${theme.palette.blue};
    &:before {
      border-color: #00ccff;
    }
  }
`

export const Header = ({ elevation = 1, onToggleDrawer }) => {

  useEffect(() => {
    // トップに遷移
    if (document) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    const headerElement = document.querySelector("#header");
    const targetElement = document.querySelector("#first-view");

    if (!headerElement) return;
    if (!targetElement) {
      const boxElement = document.querySelector("#box");
      boxElement.style.height = `${window.getComputedStyle(headerElement, null).height}`;
      headerElement.setAttribute("data-active", true);
      return;
    }

    const observeHandler = (entries) => {
      headerElement.setAttribute("data-active", !entries[0].isIntersecting);
    }

    const headerObserver = new window.IntersectionObserver(observeHandler);
    headerObserver.observe(targetElement);
  }, []);

  const [isBlogHovered, setIsBlogHovered] = useState(false);
  const [isProgrammingHovered, setIsProgrammingHovered] = useState(false);
  const [isServiceHovered, setIsServiceHovered] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              components {
                appbar {
                  position
                  links {
                    title
                    url
                  }
                }
                footer {
                  columns {
                    links {
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({
        site: {
          siteMetadata: {
            title,
            components: {
              appbar: { links, position },
              footer: { columns }
            }
          }
        }
      }) => (
        <>
          <Wrapper id={"header"}>
            <Main>
              <Box>
                <Logo href="/">
                  <TitleWrapper>
                    <Title>{title}</Title>
                    <SubTitle>ビジネスエンジニアのノウハウ</SubTitle>
                  </TitleWrapper>
                </Logo>
                <LinkWrapper className="pc">
                  <NavLink
                    to={`/posts/page/1`}
                    onMouseEnter={() => setIsBlogHovered(true)}
                    onMouseLeave={() => setIsBlogHovered(false)}
                    >BLOG</NavLink>
                  <NavLink
                    to={'/category/プログラミング/page/1'}
                    onMouseEnter={() => setIsProgrammingHovered(true)}
                    onMouseLeave={() => setIsProgrammingHovered(false)}
                    >PROGRAMMING</NavLink>
                  <NavLink
                    to={`/posts/page/1`}
                    onMouseEnter={() => setIsServiceHovered(true)}
                    onMouseLeave={() => setIsServiceHovered(false)}
                    >SERVICE</NavLink>
                </LinkWrapper>
                <IconButton
                  className="sp"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={onToggleDrawer}
                  style={{ padding: "0", marginLeft: 'auto' }}
                >
                  <MdMenu />
                </IconButton>
              </Box>
            </Main>
            <BlogSubMenuWrapper>
              <BlogSubMenu
                data-hover={isBlogHovered}
                onMouseEnter={() => setIsBlogHovered(true)}
                onMouseLeave={() => setIsBlogHovered(false)}
              >
                <Box>
                  <SubMenuWrapper>
                  {
                    columns[0].links.map((link, i) => {
                      return(<SubMenu key={i} to={link.url}>{link.title}</SubMenu>)
                    })
                  }
                  </SubMenuWrapper>
                </Box>
              </BlogSubMenu>
              <BlogSubMenu
                data-hover={isProgrammingHovered}
                onMouseEnter={() => setIsProgrammingHovered(true)}
                onMouseLeave={() => setIsProgrammingHovered(false)}
              >
                <Box>
                  <SubMenuWrapper>
                  {
                    GetCategoryTags("プログラミング").map((tag, i) => {
                      return(<SubMenu key={i} to={`/tag/${tag}/page/1`}>{tag}</SubMenu>)
                    })
                  }
                  </SubMenuWrapper>
                </Box>
              </BlogSubMenu>
              <BlogSubMenu
                data-hover={isServiceHovered}
                onMouseEnter={() => setIsServiceHovered(true)}
                onMouseLeave={() => setIsServiceHovered(false)}
              >
                <Box>
                  <SubMenuWrapper>
                  {
                    columns[1].links.map((link, i) => {
                      return(<SubMenu key={i} to={link.url}>{link.title}</SubMenu>)
                    })
                  }
                  </SubMenuWrapper>
                </Box>
              </BlogSubMenu>
            </BlogSubMenuWrapper>
          </Wrapper>
          <DummyBox id="box" />
        </>
      )}
    />
  );
};
